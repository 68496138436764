import { omit } from 'lodash';
import {
  Reply as ServerReply,
  Author as ServerAuthor,
  EnrichedLiveSiteReview,
  Review as ServerReview,
  AuthorType,
  Vote as ServerVote,
  Type as VoteType,
  ModerationStatus,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { unreachable } from '~/ts-utils';
import type { Review, Reply, Author } from '../reviews-api-types';
import { CurrentUserVoteState } from '~reviews/common/store/reviews/votes/review-vote-types';

const toServerAuthor = (author: Author): ServerAuthor =>
  author.type === 'UNKNOWN'
    ? { authorType: author.type as AuthorType }
    : {
        authorName: author.name,
        authorType: author.type as AuthorType,
        contactId: author.id,
      };

const toServerReply = (reply: Reply): ServerReply => ({
  message: reply.content,
  createdDate: new Date(reply.createdDate),
});

export const toServerReview = (review: Review): ServerReview =>
  omit(
    {
      ...review,
      moderation: {
        moderationStatus: review.moderationStatus as ModerationStatus,
      },
      entityId: review.resourceId,
      createdDate: new Date(review.createdDate),
      author: toServerAuthor(review.author),
      reply: review.reply ? toServerReply(review.reply) : undefined,
    },
    'resourceId',
  );

export const toServerEnrichedReview = (review: Review): EnrichedLiveSiteReview => ({
  id: review.id,
  review: toServerReview(review),
  currentUserVotes: review.currentUserVote as any, // TODO: fix this
});
