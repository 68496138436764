import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { TranslationKey } from '~reviews/locale-types';
import { ErrorLoud, ReviewCreateError } from '../../common-types';

export function getReviewCreateError(error: AmbassadorHTTPError): ReviewCreateError {
  switch (
    error.response?.status // TODO: find out why error.code and error.httpStatus is undefined.
  ) {
    case 409: {
      return {
        type: 'EMAIL_EXISTS_ERROR',
        key: 'toast.error-review-publish-email',
        ctaKey: 'toast.error-review-publish-email-cta',
      };
    }
    case 403: {
      if (
        error.response?.data?.details?.applicationError?.code === 'VisitorContactBelongsToMember'
      ) {
        return { type: 'MEMBER_CONTACT_EXISTS_ERROR' };
      } else {
        return {
          type: 'TRANSLATION',
          key: 'toast.error-review-publish-permission',
        };
      }
    }
    default: {
      return {
        type: 'TRANSLATION',
        key:
          (
            {
              '-1': 'toast.error-review-publish-connection',
            } as Record<string, TranslationKey>
          )[String(error.response?.status)] || 'toast.error-review-publish-generic',
      };
    }
  }
}

// TODO: check it - do we even get 403 when creating reply?
export function getReplyCreateError(error: AmbassadorHTTPError): ErrorLoud {
  return {
    type: 'TRANSLATION',
    key:
      (
        {
          '403': 'toast.error-review-publish-permission',
          '-1': 'toast.error-review-publish-connection',
        } as Record<string, TranslationKey>
      )[String(error.httpStatus)] || 'toast.error-review-publish-generic',
  };
}

export function getGenericError(status: number | undefined): ErrorLoud {
  return {
    type: 'TRANSLATION',
    key:
      (
        {
          '403': 'toast.error-permission',
          '-1': 'toast.error-connection',
        } as Record<string, TranslationKey>
      )[String(status)] || 'toast.error-generic',
  };
}
