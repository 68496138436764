import { ReviewContent } from '~commons/types';

export type OperationDescriptor =
  | {
      type: 'REVIEW';
      operation: 'CREATE' | 'UPDATE';
      content: ReviewContent;
    }
  | {
      type: 'REPLY';
      operation: 'CREATE' | 'UPDATE';
      content: string;
    };

export type BeforeCrudResult =
  | {
      type: 'ERROR';
      message: string;
    }
  | { type: 'OK'; content: string | ReviewContent };

export type BeforeCrud = (comment: OperationDescriptor) => Promise<BeforeCrudResult>;

export const initBeforeCrud = (): {
  executeBeforeCrud: BeforeCrud;
  onBeforeCrud: (beforeCrud: BeforeCrud) => void;
} => {
  let _beforeCrud: BeforeCrud = ({ content }) =>
    Promise.resolve({
      type: 'OK' as const,
      content,
    });
  return {
    onBeforeCrud: (beforeCrud) => {
      _beforeCrud = beforeCrud;
    },
    executeBeforeCrud: (...args) => _beforeCrud(...args),
  };
};
