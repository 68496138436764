import { mapValues } from 'lodash';
import { ConnectionState } from '../../common/dev-tools-types';
import {
  wrapWithDevConnectionState,
  wrapWithErrorTracking,
} from '../../common/wrap-with-dev-connection-state';

export function withMiddlewareDevTools<
  API extends { [key: string]: (...args: any[]) => Promise<any> },
>(api: API, getDevState: () => ConnectionState, trackError?: (e: any, args: any) => void): API {
  const withError = wrapWithErrorTracking(trackError);
  return mapValues(api, (fn) => withError(wrapWithDevConnectionState(fn, getDevState))) as any;
}
