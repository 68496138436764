import { combineReducers } from 'redux';
import { baseParamsReducer } from './base-params/base-params-reducer';
import { membersReducer } from './members/members-reducer';
import { reviewsReducer } from './reviews/reviews-reducer';
import { configurationReducer } from './configuration/configuration-reducer';

export const rootReducer = combineReducers({
  baseParams: baseParamsReducer,
  members: membersReducer,
  reviews: reviewsReducer,
  configuration: configurationReducer,
});
