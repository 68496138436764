import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { isEqual, mapValues } from 'lodash';
import { keys } from '~/ts-utils';
import { DevToolsState, DevToolsApi } from '../../../common/dev-tools-types';

export const initDevToolsStore = ({
  onChange,
  isDebug,
  wixCodeApi,
}: {
  wixCodeApi: IWixAPI;
  onChange: (api: DevToolsState, prevApi: DevToolsState) => void;
  isDebug: boolean;
}): DevToolsApi => {
  const defaultValues: DevToolsState = {
    panelIsOpen: false,
    showBottomControls: 'auto',
    specialStates: 'none',
    crudState: { type: 'NO_MODIFICATION' },
    middlewareState: { type: 'NO_MODIFICATION' },
    mediaUploadState: { type: 'NO_MODIFICATION' },

    showContainerBorder: false,
    hideTitle: 'auto',
    hideSorting: 'auto',
    hideFiltering: 'auto',
    hideReviewDate: 'auto',
    hideReviewAuthor: 'auto',
  };

  let state: DevToolsState = isDebug
    ? (() => {
        if (!wixCodeApi.location.query.wrDevToolsState) {
          return defaultValues;
        }
        try {
          const stateFromQuery = JSON.parse(
            decodeURIComponent(wixCodeApi.location.query.wrDevToolsState),
          );
          return mapValues(defaultValues, (defaultValue, key) => {
            const savedValue = stateFromQuery[key];
            return savedValue === undefined ? defaultValue : savedValue;
          });
        } catch {
          return defaultValues;
        }
      })()
    : // No syncing in non-debug mode to not confuse users
      defaultValues;

  const syncToQuery = () => {
    if (!isDebug) {
      return;
    }
    const diff = keys(defaultValues).reduce<Partial<DevToolsState>>((acc, key) => {
      if (!isEqual(defaultValues[key], state[key])) {
        // @ts-expect-error
        acc[key] = state[key];
      }
      return acc;
    }, {});
    if (keys(diff).length === 0) {
      wixCodeApi.location.queryParams.remove(['wrDevToolsState']);
    } else {
      wixCodeApi.location.queryParams.add({
        wrDevToolsState: encodeURIComponent(JSON.stringify(diff)),
      });
    }
  };

  const set: DevToolsApi['set'] = (key, value) => {
    const prevState = state;
    state = {
      ...state,
      [key]: value,
    };
    syncToQuery();
    onChange(state, prevState);
  };

  return {
    set,
    reset: (key) => {
      const prevState = state;
      state = {
        ...state,
        [key]: defaultValues[key],
      };
      syncToQuery();
      onChange(state, prevState);
    },
    resetAll: () => {
      const prevState = state;
      state = { ...defaultValues, panelIsOpen: state.panelIsOpen };
      syncToQuery();
      onChange(state, prevState);
    },
    breakCrud: (delayInSeconds: number, status: number = 500, response: any) =>
      set('crudState', { type: 'BROKEN', delay: delayInSeconds, status, response }),
    delayCrud: (delayInSeconds: number) =>
      set('crudState', { type: 'DELAYED', delay: delayInSeconds }),
    fixCrud: () => set('crudState', { type: 'NO_MODIFICATION' }),
    breakFetch: (delayInSeconds: number, status: number = 500) =>
      set('middlewareState', { type: 'BROKEN', delay: delayInSeconds, status }),
    delayFetch: (delayInSeconds: number) =>
      set('middlewareState', { type: 'DELAYED', delay: delayInSeconds }),
    fixFetch: () => set('middlewareState', { type: 'NO_MODIFICATION' }),
    invokeAppCrash: () => set('specialStates', 'crash'),
    getState: () => {
      return state;
    },
  };
};
