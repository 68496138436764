import { AppThunk } from '../store-types';
import { BaseParamsState } from './base-params-types';
import { parseInstance } from '~commons/instance/parse-instance';
import { isSledRun } from '~commons/services/is-sled-run';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS' as const;
export const setBaseParamsSuccess = (
  payload: Exclude<BaseParamsState, 'registrationManuallyApproved'>,
) => ({
  type: SET_BASIC_PARAMS,
  payload,
});

export const SET_VIEW_MODE = 'SET_VIEW_MODE' as const;
export const setViewMode = (payload: string) => ({ type: SET_VIEW_MODE, payload });

export const UPDATE_INSTANCE = 'UPDATE_INSTANCE' as const;
export const updateInstance = ({ instance }: { instance: string }) => ({
  type: UPDATE_INSTANCE,
  payload: { instance, instanceValues: parseInstance(instance) },
});

export function setBaseParams({
  appDefinitionId,
}: {
  appDefinitionId: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState, { wixCodeApi, platformAPIs, instanceManager }) => {
    const viewMode = wixCodeApi.window.viewMode;
    dispatch(
      setBaseParamsSuccess({
        viewMode,
        instance: instanceManager.getInstance(),
        instanceValues: instanceManager.getInstanceValues(),
        isSeo: wixCodeApi.seo.isInSEO(),
        isDebug: !!wixCodeApi.location.query.debug,
        disableLogs: !!wixCodeApi.location.query.disableLogs,
        isProduction: process.env.NODE_ENV === 'production',
        isSled: isSledRun(wixCodeApi.location),
        pageNumber: String(platformAPIs.bi?.pageNumber),
        appDefinitionId,
        screenshotMode:
          !!wixCodeApi.location.query.screenshotMode &&
          wixCodeApi.location.query.screenshotMode !== 'false',
        mockImages:
          !!wixCodeApi.location.query.mockImages &&
          wixCodeApi.location.query.mockImages !== 'false',
      }),
    );
  };
}
