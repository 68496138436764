import { Middleware } from 'redux';
import { AllActions } from '../store-types';
import { biEventsExecutor } from '../../bi/bi-events';
import { BiEventsExecutor, BiLogger } from '../../bi/bi-types';
import { InstanceManager } from '~commons/instance/get-instance';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getLoggerDefaultValues = (instanceManager: InstanceManager) => {
  const {
    // biToken,
    appDefId,
    uid,
    aid,
    // instanceId,
    metaSiteId,
  } = instanceManager.getInstanceValues();

  return {
    msid: metaSiteId,
    // _instanceId: instanceId,
    // biToken,
    app_id: appDefId,
    site_member_id: uid ?? undefined,
    visitor_id: aid ?? undefined,
    src: 69,
  };
};

export const initBiMiddleware = (instanceManager: InstanceManager, flowAPI: ControllerFlowAPI) => {
  const { platformAPIs } = flowAPI.controllerConfig;
  const maybeLoggerFactory = platformAPIs && platformAPIs.biLoggerFactory?.();

  // In iframe we get different object here, so lets catch some ducks
  const loggerFactory = maybeLoggerFactory?.logger
    ? // ooi
      maybeLoggerFactory
    : // iframe - factory is nested deeper
      (maybeLoggerFactory as any)?.factory?.();

  if (!loggerFactory || !loggerFactory.logger) {
    throw new Error('BI Logger factory not found or is unsupported');
  }

  const logger = loggerFactory.logger();
  const updateLoggerDefaults = () =>
    loggerFactory.updateDefaults(getLoggerDefaultValues(instanceManager));

  updateLoggerDefaults();

  instanceManager.onChange(updateLoggerDefaults);

  return createBiMiddleware(logger, biEventsExecutor, flowAPI);
};

const createBiMiddleware = (
  logger: BiLogger,
  biEventsExecutor: (args: BiEventsExecutor) => void,
  flowAPI: ControllerFlowAPI,
): Middleware => {
  return (store) => (next) => (action: AllActions) => {
    const state = store.getState();
    try {
      biEventsExecutor({ action, state, logger, flowAPI });
    } catch (e) {
      log(`Event ${action.type} bi logger error: ${e}`);
    }
    return next(action);
  };
};

const log = (message: string) => {
  process.env.NODE_ENV === 'development' && console.error(message);
};
