import {
  FETCH_DEEP_LINK_SUCCESS,
  FETCH_REVIEWS_INITIAL_SUCCESS,
  REFETCH_REVIEWS_SUCCESS,
} from '../reviews/paging/reviews-paging-actions';
import { AllActions } from '../store-types';
import { CurrentOwner } from '../../common-types';
import { SET_BASIC_PARAMS, UPDATE_INSTANCE } from '../base-params/base-params-actions';

export type MembersState = {
  currentOwner: CurrentOwner;
};

export function membersReducer(
  state: MembersState = {
    currentOwner: { type: 'UNKNOWN' },
  },
  action: AllActions,
): MembersState {
  switch (action.type) {
    case UPDATE_INSTANCE:
    case SET_BASIC_PARAMS: {
      const { aid: visitorId } = action.payload.instanceValues;
      if (!visitorId || state.currentOwner.type === 'MEMBER') {
        return state;
      } else {
        return {
          ...state,
          currentOwner: { type: 'ANONYMOUS', identityId: visitorId },
        };
      }
    }
    case FETCH_DEEP_LINK_SUCCESS:
    case FETCH_REVIEWS_INITIAL_SUCCESS: {
      const { currentMember: initialCurrentMember } = action.payload.response;
      const currentOwner = initialCurrentMember
        ? {
            type: 'MEMBER' as const,
            member: initialCurrentMember,
          }
        : state.currentOwner;
      return {
        currentOwner,
      };
    }
    case REFETCH_REVIEWS_SUCCESS: {
      const { response } = action.payload;
      const { currentMember } = response;
      const currentOwner = currentMember
        ? {
            type: 'MEMBER' as const,
            member: currentMember,
          }
        : state.currentOwner;
      return {
        currentOwner,
      };
    }
    default:
      return state;
  }
}
