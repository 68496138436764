import { isEqual } from 'lodash';
import { Middleware } from 'redux';
import { unreachable } from '~/ts-utils';
import { AllActions, State, Dispatch } from '../store-types';
import { getReviewsSummary } from './ratings-selectors';

type RatingState =
  | { type: 'IDLE' }
  | { type: 'PENDING' }
  | { type: 'ERROR' }
  | { type: 'READY_EMPTY' }
  | { type: 'READY'; overall: number; totalReviews: number };

export type WatchRatings = {
  subscribe: (listener: Listener) => void;
  unsubscribe: (listener: Listener) => void;
  getState: () => RatingState;
};
type Listener = (state: RatingState) => void;

export const createRatingsWatcher = (): {
  middleware: Middleware<{}, State, Dispatch>;
  watcher: WatchRatings;
} => {
  let lastState: RatingState = { type: 'IDLE' };
  let listeners: Listener[] = [];
  return {
    middleware: (store) => (next) => (action: AllActions) => {
      const result = next(action);
      const resourceState = store.getState().reviews;
      const currentState = ((): RatingState => {
        switch (resourceState.type) {
          case 'INITIAL':
            return { type: 'IDLE' };
          case 'PENDING':
            return { type: 'PENDING' };
          case 'ERROR':
            return { type: 'ERROR' };
          case 'READY':
            const summary = getReviewsSummary(resourceState);
            if (summary.totalReviews === 0) {
              return { type: 'READY_EMPTY' };
            }
            return { type: 'READY', overall: summary.overall, totalReviews: summary.totalReviews };

          default:
            throw unreachable(resourceState);
        }
      })();
      if (!isEqual(currentState, lastState)) {
        listeners.forEach((l) => l(currentState));
        lastState = currentState;
      }
      return result;
    },
    watcher: {
      getState: () => lastState,
      subscribe: (l: Listener) => listeners.push(l),
      unsubscribe: (l: Listener) => {
        listeners = listeners.filter((li) => li !== l);
      },
    },
  };
};
