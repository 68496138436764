import { AllActions, AppThunk } from '../../store-types';
import { getGenericError } from '../reviews-errors';
import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { isOfType, unreachable } from '~/ts-utils';
import { ErrorInfo } from '../../../common-types';
import { CurrentUserVoteState } from './review-vote-types';
import { getReviewState } from '../reviews/reviews-state-selectors';
import { biClickHelpfulBtnSuccess } from '~reviews/common/store/actions/bi-actions';
import { getSiteIsTemplate } from '../../base-params/base-params-selectors';
import { requestLogin } from '../../actions/request-login';

export const VOTE_REQUEST = 'VOTE_REQUEST' as const;
export const VOTE_FAILURE = 'VOTE_FAILURE' as const;
export const VOTE_SUCCESS = 'VOTE_SUCCESS' as const;

export const voteRequest = (paylaod: {
  reviewId: string;
  nextCurrentUserVoteState: CurrentUserVoteState;
}) => ({
  type: VOTE_REQUEST,
  payload: paylaod,
});

export const voteFailure = (paylaod: {
  reviewId: string;
  error: ErrorInfo;
  nextCurrentUserVoteState: CurrentUserVoteState;
}) => ({
  type: VOTE_FAILURE,
  payload: paylaod,
});

export const voteSuccess = (payload: {
  reviewId: string;
  nextCurrentUserVoteState: CurrentUserVoteState;
}) => ({
  type: VOTE_SUCCESS,
  payload,
});

export const voteOnReview =
  ({ clicked, reviewId }: { reviewId: string; clicked: 'UPVOTE' | 'DOWNVOTE' }): AppThunk =>
  async (dispatch, getState, { wixReviewsApi }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const reviewsState = getState().reviews;
    const reviewState = getReviewState(reviewId, reviewsState);
    if (!reviewState) {
      return;
    }

    const currentUserVoteState = reviewState.votes.currentUserVote;
    if (currentUserVoteState.pendingAction) {
      return;
    }
    const nextCurrentUserVoteState = (() => {
      switch (currentUserVoteState.type) {
        case 'NEUTRAL':
          return { ...currentUserVoteState, pendingAction: clicked };
        case 'UPVOTED':
          return {
            ...currentUserVoteState,
            pendingAction: clicked === 'UPVOTE' ? ('REMOVE' as const) : ('DOWNVOTE' as const),
          };
        case 'DOWNVOTED':
          return {
            ...currentUserVoteState,
            pendingAction: clicked === 'DOWNVOTE' ? ('REMOVE' as const) : ('UPVOTE' as const),
          };
        default:
          throw unreachable(currentUserVoteState);
      }
    })();

    dispatch(voteRequest({ reviewId, nextCurrentUserVoteState }));

    (() => {
      switch (nextCurrentUserVoteState.pendingAction) {
        case 'UPVOTE':
          return wixReviewsApi.upvote({ reviewId }).then((voteId) => {
            dispatch(biClickHelpfulBtnSuccess({ reviewId, action: 'upvote' }));
            return dispatch(
              voteSuccess({
                reviewId,
                nextCurrentUserVoteState: { type: 'UPVOTED', voteId },
              }),
            );
          });
        case 'DOWNVOTE':
          return wixReviewsApi.downvote({ reviewId }).then((voteId) => {
            dispatch(biClickHelpfulBtnSuccess({ reviewId, action: 'downvote' }));
            return dispatch(
              voteSuccess({
                reviewId,
                nextCurrentUserVoteState: { type: 'DOWNVOTED', voteId },
              }),
            );
          });
        case 'REMOVE':
          return wixReviewsApi.removeVote({ voteId: nextCurrentUserVoteState.voteId }).then(() => {
            dispatch(
              biClickHelpfulBtnSuccess({
                reviewId,
                action: clicked === 'UPVOTE' ? 'upvote' : 'downvote',
              }),
            );
            return dispatch(
              voteSuccess({
                reviewId,
                nextCurrentUserVoteState: { type: 'NEUTRAL' },
              }),
            );
          });
        default:
          throw unreachable(nextCurrentUserVoteState);
      }
    })().catch((e) =>
      dispatch(
        voteFailure({
          reviewId,
          error: getGenericError((e as AmbassadorHTTPError).httpStatus),
          nextCurrentUserVoteState: currentUserVoteState,
        }),
      ),
    );
  };

export type VoteActions =
  | ReturnType<typeof voteFailure>
  | ReturnType<typeof voteRequest>
  | ReturnType<typeof voteSuccess>;

export const isVoteAction: (action: AllActions) => action is VoteActions = isOfType([
  VOTE_REQUEST,
  VOTE_FAILURE,
  VOTE_SUCCESS,
]);
