import { isDefined } from '~/ts-utils';
import { getReviewsSummary } from '~reviews/common/store/reviews/ratings-selectors';
import { getListSectionsState } from '~reviews/common/store/reviews/reviews-selectors';
import { ReviewsState } from '~reviews/common/store/reviews/reviews-types';
import { Config } from '~reviews/controller/configuration-store';

type ReviewsSeoTags = {
  appName: 'Wix Reviews';
  itemType: 'Product';
  itemId: string;
  itemReviewed: string;
  review: {
    '@type': 'Review';
    reviewRatings: {
      '@type': 'Rating';
      ratingValue: number;
      bestRating: number;
      worstRating: number;
    };
    author: {
      '@type': 'Person';
      name: string;
    };
    hasReviewBody: boolean;
    reviewBody: string;
    datePublished: string;
  }[];
  aggregateRating: {
    '@type': 'AggregateRating';
    ratingValue: number;
    bestRating: number;
    worstRating: number;
    reviewCount: number;
    ratingCount: number;
  };
};

export const getSeoTags = (
  reviewsState: ReviewsState,
  { resourceId, namespace }: Config,
): ReviewsSeoTags | undefined => {
  if (reviewsState.type !== 'READY') {
    return undefined;
  }
  const listState = getListSectionsState(reviewsState);
  if (listState.type !== 'LIST') {
    // Not a list view - or there are no reviews, so no SEO tags
    return undefined;
  }
  const summary = getReviewsSummary(reviewsState);

  return {
    appName: 'Wix Reviews',
    itemType: 'Product',
    itemId: resourceId,
    itemReviewed: namespace,
    review: listState.reviewsList
      .map((reviewState) => {
        const authorName =
          reviewState.review.author.type !== 'UNKNOWN' ? reviewState.review.author.name : undefined;
        if (!authorName) {
          // We can't render SEO tags without an author name
          return undefined;
        }
        const reviewBody = [reviewState.review.content.title, reviewState.review.content.body]
          .filter(Boolean)
          .join(' ');

        return {
          '@type': 'Review' as const,
          reviewRatings: {
            '@type': 'Rating' as const,
            ratingValue: reviewState.review.content.rating,
            bestRating: 5,
            worstRating: 1,
          },
          author: {
            '@type': 'Person' as const,
            name: authorName,
          },
          hasReviewBody: !!reviewBody,
          reviewBody,
          datePublished: reviewState.review.createdDate,
        };
      })
      .filter(isDefined),
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: summary.overall,
      bestRating: 5,
      worstRating: 1,
      reviewCount: summary.totalReviews,
      ratingCount: summary.totalReviews,
    },
  };
};
