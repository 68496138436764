import { unreachable, isOfType } from '~/ts-utils';
import {
  INIT_REPLY,
  CANCEL_CREATING_REPLY,
  EDIT_REPLY,
  CREATE_REPLY_REQUEST,
  CREATE_REPLY_SUCCESS,
  CREATE_REPLY_FAILURE,
  DELETE_REPLY_REQUEST,
  DELETE_REPLY_SUCCESS,
  DELETE_REPLY_FAILURE,
  UPDATE_REPLY_REQUEST,
  UPDATE_REPLY_FAILURE,
  UPDATE_REPLY_SUCCESS,
  RepliesCrudAction,
  CANCEL_EDITING_REPLY,
} from './replies-crud-actions';
import { ReviewsStateReady } from '../reviews-types';
import { updateReplyState } from '../review-state-invariant-utils';

export function repliesCrudReducer(
  state: ReviewsStateReady,
  action: RepliesCrudAction,
): ReviewsStateReady {
  switch (action.type) {
    case INIT_REPLY: {
      const { parentId } = action.payload;

      return updateReplyState({
        state,
        parentId,
        updateReply: (current) => (current ? current : { type: 'REPLY_TYPING', parentId }),
      });
    }
    case CANCEL_CREATING_REPLY: {
      const { parentId } = action.payload;

      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_TYPING'], current) ? current : undefined,
      });
    }
    case CANCEL_EDITING_REPLY: {
      const { parentId } = action.payload;

      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_EDITING'], current)
            ? current
            : {
                type: 'REPLY_READY',
                reply: current.reply,
                parentId: current.parentId,
              },
      });
    }
    case EDIT_REPLY: {
      const { parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_READY'], current)
            ? current
            : {
                ...current,
                type: 'REPLY_EDITING',
              },
      });
    }
    case UPDATE_REPLY_REQUEST: {
      const { parentId, content } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_EDITING'], current)
            ? current
            : {
                ...current,
                content,
                type: 'REPLY_UPDATE_PENDING',
              },
      });
    }
    case UPDATE_REPLY_SUCCESS: {
      const { reply, parentId } = action.payload;
      return {
        ...updateReplyState({
          state,
          parentId,
          updateReply: (current) =>
            !current || !isOfType(['REPLY_UPDATE_PENDING'], current)
              ? current
              : {
                  type: 'REPLY_READY',
                  reply,
                  parentId,
                },
          updateEffects: () => ({
            toast: {
              isHidden: true,
              skin: 'success',
              message: { type: 'TRANSLATION', key: 'toast.reply-updated' },
              timestamp: Date.now(),
            },
          }),
        }),
      };
    }
    case UPDATE_REPLY_FAILURE: {
      const { error, parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_UPDATE_PENDING'], current)
            ? current
            : {
                type: 'REPLY_EDITING',
                reply: current.reply,
                parentId,
              },
        updateEffects: (effects, current) => ({
          toast:
            error.type === 'SILENT'
              ? effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    case CREATE_REPLY_REQUEST: {
      const { content, parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_TYPING'], current)
            ? current
            : {
                type: 'REPLY_CREATE_PENDING',
                parentId,
                content,
              },
      });
    }

    case CREATE_REPLY_SUCCESS: {
      const { reply, parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_CREATE_PENDING'], current)
            ? current
            : {
                type: 'REPLY_READY',
                parentId,
                reply,
              },
        updateEffects: () => ({
          toast: {
            isHidden: true,
            skin: 'success',
            message: { type: 'TRANSLATION', key: 'toast.reply-published' },
            timestamp: Date.now(),
          },
        }),
      });
    }
    case CREATE_REPLY_FAILURE: {
      const { error, parentId } = action.payload;

      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_CREATE_PENDING'], current)
            ? current
            : {
                ...current,
                type: 'REPLY_TYPING',
              },
        updateEffects: () => ({
          toast:
            error.type === 'SILENT'
              ? state.effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    case DELETE_REPLY_REQUEST: {
      const { parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_READY'], current)
            ? current
            : { ...current, type: 'REPLY_DELETE_PENDING' },
      });
    }
    case DELETE_REPLY_SUCCESS: {
      const { parentId } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_DELETE_PENDING'], current) ? current : undefined,
        updateEffects: () => ({
          toast: {
            skin: 'success',
            message: { type: 'TRANSLATION', key: 'toast.reply-deleted' },
            timestamp: Date.now(),
          },
        }),
      });
    }
    case DELETE_REPLY_FAILURE: {
      const { parentId, error } = action.payload;
      return updateReplyState({
        state,
        parentId,
        updateReply: (current) =>
          !current || !isOfType(['REPLY_DELETE_PENDING'], current)
            ? current
            : { ...current, type: 'REPLY_READY' },
        updateEffects: (effects) => ({
          toast:
            error.type === 'SILENT'
              ? effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    default:
      throw unreachable(action);
  }
}
