import { ReviewsActions } from '../reviews/reviews-action-types';
import { SET_BASIC_PARAMS, SET_VIEW_MODE, UPDATE_INSTANCE } from './base-params-actions';
import { BaseParamsState, BaseParamsActions } from './base-params-types';

export function baseParamsReducer(
  state: Partial<BaseParamsState> = { viewMode: 'site' },
  action: BaseParamsActions | ReviewsActions,
): Partial<BaseParamsState> {
  switch (action.type) {
    case SET_BASIC_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_VIEW_MODE:
      return { ...state, viewMode: action.payload };
    case UPDATE_INSTANCE:
      if (state.instance === action.payload.instance) {
        return state;
      }
      return {
        ...state,
        instance: action.payload.instance,
        instanceValues: action.payload.instanceValues,
      };
    default:
      return state;
  }
}
