import { AppThunk, AllActions } from '../../store-types';
import { ErrorInfo } from '../../../common-types';
import { isOfType } from '~/ts-utils';
import { getGenericError, getReplyCreateError } from '../reviews-errors';
import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { Reply } from '../../../../controller/lib/reviews-api-types';
import { getReviewState } from '../reviews/reviews-state-selectors';
import { biReplyDeleted, biReplyPublishSuccess } from '~reviews/common/store/actions/bi-actions';
import { getSiteIsTemplate } from '~reviews/common/store/base-params/base-params-selectors';
import { requestLogin } from '~reviews/common/store/actions/request-login';

export const INIT_REPLY = 'INIT_REPLY' as const;
export const EDIT_REPLY = 'EDIT_REPLY' as const;
export const CANCEL_CREATING_REPLY = 'CANCEL_CREATING_REPLY' as const;
export const CANCEL_EDITING_REPLY = 'CANCEL_EDITING_REPLY' as const;

export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST' as const;
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS' as const;
export const CREATE_REPLY_FAILURE = 'CREATE_REPLY_FAILURE' as const;

export const UPDATE_REPLY_REQUEST = 'UPDATE_REPLY_REQUEST' as const;
export const UPDATE_REPLY_FAILURE = 'UPDATE_REPLY_FAILURE' as const;
export const UPDATE_REPLY_SUCCESS = 'UPDATE_REPLY_SUCCESS' as const;

export const DELETE_REPLY_REQUEST = 'DELETE_REPLY_REQUEST' as const;
export const DELETE_REPLY_FAILURE = 'DELETE_REPLY_FAILURE' as const;
export const DELETE_REPLY_SUCCESS = 'DELETE_REPLY_SUCCESS' as const;

export const createReplyRequest = (payload: { content: string; parentId: string }) => ({
  type: CREATE_REPLY_REQUEST,
  payload,
});

export const createReplySuccess = (payload: {
  reply: Reply;
  parentId: string;
  requestDuration: number;
}) => ({
  type: CREATE_REPLY_SUCCESS,
  payload,
});

export const createReplyFailure = (payload: { error: ErrorInfo; parentId: string }) => ({
  type: CREATE_REPLY_FAILURE,
  payload,
});

export function createReply({
  content,
  parentId,
}: {
  content: string;
  parentId: string;
}): AppThunk {
  return async (dispatch, getState, { wixReviewsApi, executeBeforeCrud }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const parentReview = getReviewState(parentId, getState().reviews);

    if (!parentReview) {
      return;
    }
    dispatch(createReplyRequest({ content, parentId }));

    const interceptorResult = await executeBeforeCrud({
      type: 'REPLY',
      operation: 'CREATE',
      content,
    });
    if (interceptorResult.type === 'ERROR') {
      dispatch(
        createReplyFailure({
          parentId,
          error: { type: 'CUSTOM', message: interceptorResult.message },
        }),
      );
      return;
    }
    const requestStartTime = Date.now();
    const promise = wixReviewsApi.setReply({
      replyContent: interceptorResult.content as string,
      parentId,
    });

    try {
      const response = await promise;
      dispatch(
        createReplySuccess({
          reply: response,
          parentId,
          requestDuration: Date.now() - requestStartTime,
        }),
      );
      dispatch(
        biReplyPublishSuccess({ reviewId: parentId, replyText: response.content, isEdited: false }),
      );
    } catch (e) {
      return dispatch(
        createReplyFailure({
          error: getReplyCreateError(e as AmbassadorHTTPError),
          parentId,
        }),
      );
    }
  };
}

export const initReply = (payload: { parentId: string }) => ({
  type: INIT_REPLY,
  payload,
});

export const cancelCreatingReply = (payload: { parentId: string }) => ({
  type: CANCEL_CREATING_REPLY,
  payload,
});

export const cancelEditingReply = (payload: { parentId: string }) => ({
  type: CANCEL_EDITING_REPLY,
  payload,
});

export const editReply = (payload: { parentId: string }) => ({
  type: EDIT_REPLY,
  payload,
});

export const deleteReplyRequest = (payload: { parentId: string }) => ({
  type: DELETE_REPLY_REQUEST,
  payload,
});

export const deleteReplySuccess = (payload: { parentId: string }) => ({
  type: DELETE_REPLY_SUCCESS,
  payload,
});

export const deleteReplyFailure = (payload: { parentId: string; error: ErrorInfo }) => ({
  type: DELETE_REPLY_FAILURE,
  payload,
});

export function deleteReply({ parentId }: { parentId: string }): AppThunk {
  return async (dispatch, getState, { wixReviewsApi }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const replyState = getReviewState(parentId, getState().reviews)?.replyState;
    if (replyState?.type !== 'REPLY_READY') {
      return;
    }
    dispatch(deleteReplyRequest({ parentId: replyState.parentId }));

    const promise = wixReviewsApi.removeReply({ reviewId: parentId });

    return promise
      .then(() => {
        dispatch(deleteReplySuccess({ parentId: replyState.parentId }));
        dispatch(biReplyDeleted({ reviewId: replyState.parentId }));
      })
      .catch((e: AmbassadorHTTPError) =>
        dispatch(
          deleteReplyFailure({
            parentId: replyState.parentId,
            error: getGenericError(e.httpStatus),
          }),
        ),
      );
  };
}

export const updateReplyRequest = (payload: { parentId: string; content: string }) => ({
  type: UPDATE_REPLY_REQUEST,
  payload,
});

export const updateReplySuccess = (payload: {
  parentId: string;
  reply: Reply;
  requestDuration: number;
}) => ({
  type: UPDATE_REPLY_SUCCESS,
  payload,
});

export const updateReplyFailure = (payload: { parentId: string; error: ErrorInfo }) => ({
  type: UPDATE_REPLY_FAILURE,
  payload,
});

export function updateReply({
  parentId,
  content,
}: {
  content: string;
  parentId: string;
}): AppThunk {
  return async (dispatch, getState, { wixReviewsApi, executeBeforeCrud }) => {
    if (getSiteIsTemplate(getState())) {
      return dispatch(requestLogin());
    }
    const replyState = getReviewState(parentId, getState().reviews)?.replyState;
    if (replyState?.type !== 'REPLY_EDITING') {
      return;
    }

    dispatch(updateReplyRequest({ parentId, content }));

    const interceptorResult = await executeBeforeCrud({
      type: 'REPLY',
      operation: 'UPDATE',
      content,
    });
    if (interceptorResult.type === 'ERROR') {
      dispatch(
        updateReplyFailure({
          parentId,
          error: { type: 'CUSTOM', message: interceptorResult.message },
        }),
      );
      return;
    }

    const requestStartTime = Date.now();
    const promise = wixReviewsApi.setReply({
      replyContent: interceptorResult.content as string,
      parentId,
    });

    return promise
      .then((response) => {
        dispatch(
          updateReplySuccess({
            parentId,
            reply: response,
            requestDuration: Date.now() - requestStartTime,
          }),
        );
        dispatch(
          biReplyPublishSuccess({
            reviewId: parentId,
            replyText: response.content,
            isEdited: true,
          }),
        );
      })
      .catch((e: AmbassadorHTTPError) =>
        dispatch(updateReplyFailure({ parentId, error: getGenericError(e.httpStatus) })),
      );
  };
}

// Public action
export type CreateReplySuccess = ReturnType<typeof createReplySuccess>;
export type DeleteReplySuccess = ReturnType<typeof deleteReplySuccess>;

export type CreateReplyActions =
  | ReturnType<typeof createReplySuccess>
  | ReturnType<typeof createReplyRequest>
  | ReturnType<typeof createReplyFailure>;

export type DeleteReplyActions =
  | ReturnType<typeof deleteReplySuccess>
  | ReturnType<typeof deleteReplyRequest>
  | ReturnType<typeof deleteReplyFailure>;

export type UpdateReplyActions =
  | ReturnType<typeof updateReplySuccess>
  | ReturnType<typeof updateReplyRequest>
  | ReturnType<typeof updateReplyFailure>;

export type InitReply = ReturnType<typeof initReply>;
export type CancelCreatingReply = ReturnType<typeof cancelCreatingReply>;
export type CancelEditingReply = ReturnType<typeof cancelEditingReply>;
export type EditReply = ReturnType<typeof editReply>;

export type RepliesCrudAction =
  | CreateReplyActions
  | UpdateReplyActions
  | DeleteReplyActions
  | InitReply
  | CancelEditingReply
  | CancelCreatingReply
  | EditReply;

export const isRepliesCrudAction: (action: AllActions) => action is RepliesCrudAction = isOfType([
  INIT_REPLY,
  CANCEL_CREATING_REPLY,
  CANCEL_EDITING_REPLY,
  EDIT_REPLY,
  CREATE_REPLY_REQUEST,
  CREATE_REPLY_FAILURE,
  CREATE_REPLY_SUCCESS,
  DELETE_REPLY_REQUEST,
  DELETE_REPLY_FAILURE,
  DELETE_REPLY_SUCCESS,
  UPDATE_REPLY_REQUEST,
  UPDATE_REPLY_FAILURE,
  UPDATE_REPLY_SUCCESS,
]);
