import { Middleware } from 'redux';
import { AllActions, State, Dispatch } from '../store-types';
import { CREATE_REPLY_SUCCESS } from './replies/replies-crud-actions';
import { CREATE_REVIEW_SUCCESS } from './reviews/reviews-crud-actions';

export type CommentDescriptor = {
  type: 'COMMENT' | 'REPLY';
};

export type OnCommentCreate = (comment: CommentDescriptor) => void;

/** Currently exposes only onCreate method to track comment creation events.
 * **Do not use this for comment totals tracking, use [[WatchPagination]] for that.**
 */
export type WatchComments = {
  onCreate: (onCommentCreate: OnCommentCreate) => void;
};

export const createReviewsWatcher = (): {
  middleware: Middleware<{}, State, Dispatch>;
  watcher: WatchComments;
} => {
  let onCreate: OnCommentCreate | undefined;
  return {
    middleware: () => (next) => (action: AllActions) => {
      if (action.type === CREATE_REVIEW_SUCCESS) {
        onCreate?.({ type: 'COMMENT' });
      }
      if (action.type === CREATE_REPLY_SUCCESS) {
        onCreate?.({ type: 'REPLY' });
      }
      return next(action);
    },
    watcher: {
      onCreate: (onCommentCreate) => {
        onCreate = onCommentCreate;
      },
    },
  };
};
