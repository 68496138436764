import {
  FETCH_REVIEWS_INITIAL_SUCCESS,
  FETCH_REVIEWS_INITIAL_REQUEST,
  FETCH_REVIEWS_INITIAL_FAILURE,
  isReviewsPagingAction,
  UNMOUNT_RESOURCE,
  REFETCH_REVIEWS_SUCCESS,
  isReviewsRefetchAction,
  REFETCH_REVIEWS_FAILURE,
  FETCH_DEEP_LINK_REQUEST,
  FETCH_DEEP_LINK_SUCCESS,
  FETCH_DEEP_LINK_FAILURE,
  RefetchReviewsActions,
  REFETCH_REVIEWS_REQUEST,
  FOCUS_ROOT,
} from './paging/reviews-paging-actions';
import { isReviewsCrudAction } from './reviews/reviews-crud-actions';
import { ReviewsState } from './reviews-types';
import { isReviewsAction } from './reviews-action-types';
import { AllActions } from '../store-types';
import { repliesCrudReducer } from './replies/replies-crud-reducer';
import { isRepliesCrudAction } from './replies/replies-crud-actions';
import { reviewsCrudReducer } from './reviews/reviews-crud-reducer';
import { isOfType, unreachable } from '~/ts-utils';
import {
  reviewsPagingReducer,
  reviewsFetchResponseToChunk,
  reviewToReviewState,
} from './paging/reviews-paging-reducer';
import { SHOW_TOAST } from './show-toast-action';
import { isVoteAction } from './votes/review-vote-actions';
import { reviewsVoteReducer } from './votes/review-vote-reducer';
import { omit } from 'lodash';

export function reviewsReducer(
  state: ReviewsState = { type: 'INITIAL' },
  action: AllActions,
): ReviewsState {
  if (!isReviewsAction(action)) {
    return state;
  }
  if (isReviewsRefetchAction(action)) {
    return reviewsRefetchReducer(state, action);
  }
  switch (action.type) {
    case UNMOUNT_RESOURCE:
      return { type: 'INITIAL' };
    case FETCH_REVIEWS_INITIAL_REQUEST: {
      const { resourceId, namespace, pagination, requestId } = action.payload;
      return {
        type: 'PENDING',
        pendingRequestId: requestId,
        config: { resourceId, namespace, pagination },
      };
    }
    case FETCH_DEEP_LINK_REQUEST: {
      const { resourceId, namespace, pagination, requestId, reviewId } = action.payload;
      return {
        type: 'PENDING',
        pendingRequestId: requestId,
        pendingDeepLinkId: reviewId,
        config: { resourceId, namespace, pagination },
      };
    }
    case FETCH_REVIEWS_INITIAL_SUCCESS: {
      const {
        requestId,
        response,
        response: { ratingsBreakdown, permissions, currentUserReview },
      } = action.payload;
      if (isOfType(['PENDING'], state) && state.pendingRequestId === requestId) {
        const [chunk, reviews] = reviewsFetchResponseToChunk(response);
        return {
          type: 'READY',
          ratingsBreakdown,
          userReview: currentUserReview
            ? {
                type: 'CREATED',
                review: reviewToReviewState(currentUserReview),
              }
            : { type: 'NOT_CREATED', form: { type: 'HIDDEN' } },
          reviewList: {
            chunk,
            prependCurrentUserReview: false,
            type: 'READY',
            currentPage: 1,
          },
          reviewsById: currentUserReview ? omit(reviews, currentUserReview.id) : reviews,
          config: state.config,
          permissions,
          effects: {},
        };
      }
      return state;
    }
    case FETCH_DEEP_LINK_SUCCESS: {
      const {
        requestId,
        response,
        response: { ratingsBreakdown, permissions, currentUserReview },
      } = action.payload;
      if (
        state &&
        isOfType(['PENDING'], state) &&
        state.pendingRequestId === requestId &&
        state.pendingDeepLinkId
      ) {
        const reviewId = state.pendingDeepLinkId;
        const deepLinkReview = response.reviews.find((r) => r.id === reviewId);
        const reviewsById = deepLinkReview
          ? { [reviewId]: reviewToReviewState(deepLinkReview) }
          : {};
        return {
          type: 'READY',
          ratingsBreakdown,
          userReview: currentUserReview
            ? {
                type: 'CREATED',
                review: reviewToReviewState(currentUserReview),
              }
            : { type: 'NOT_CREATED', form: { type: 'HIDDEN' } },
          reviewList: {
            type: 'DEEP_LINK',
            reviewId,
            state: deepLinkReview ? 'EXISTS' : 'NOT_FOUND',
          },
          reviewsById: currentUserReview ? omit(reviewsById, currentUserReview.id) : reviewsById,
          config: state.config,
          permissions,
          effects: {
            highlight: deepLinkReview
              ? {
                  type: 'DEEP_LINK',
                  timestamp: Date.now(),
                  id: deepLinkReview.id,
                }
              : {
                  type: 'SCROLL_TO_TOP',
                  timestamp: Date.now(),
                },
          },
        };
      }
      return state;
    }
    case FETCH_DEEP_LINK_FAILURE:
    case FETCH_REVIEWS_INITIAL_FAILURE:
      if (state?.type !== 'PENDING' || state.pendingRequestId !== action.payload.requestId) {
        return state;
      }
      return {
        type: 'ERROR',
        error: '',
        config: state.config,
      };
    default:
  }

  if (state?.type !== 'READY') {
    return state;
  }

  // Other actions have meaning only when initial load is done

  if (isRepliesCrudAction(action)) {
    return repliesCrudReducer(state, action);
  }

  if (isReviewsCrudAction(action)) {
    return reviewsCrudReducer(state, action);
  }

  if (isReviewsPagingAction(action)) {
    return reviewsPagingReducer(state, action);
  }

  if (isVoteAction(action)) {
    return reviewsVoteReducer(state, action);
  }

  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        effects: {
          ...state.effects,
          toast: {
            skin: action.payload.skin,
            message: action.payload.message,
            timestamp: Date.now(),
          },
        },
      };
    case FOCUS_ROOT:
      return {
        ...state,
        effects: {
          ...state.effects,
          highlight: {
            type: 'FOCUS_ROOT',
            timestamp: Date.now(),
          },
        },
      };
    default:
      throw unreachable(action);
  }
}

function reviewsRefetchReducer(state: ReviewsState, action: RefetchReviewsActions): ReviewsState {
  switch (action.type) {
    case REFETCH_REVIEWS_REQUEST: {
      if (state.type !== 'READY') {
        return state;
      }
      return {
        ...state,
        pendingRefetchId: action.payload.requestId,
      };
    }
    case REFETCH_REVIEWS_SUCCESS: {
      const { response, requestId } = action.payload;
      if (state.type !== 'READY' || state.pendingRefetchId !== requestId) {
        return state;
      }
      const [chunk, reviews] = reviewsFetchResponseToChunk(response);
      const { currentUserReview } = response;
      return {
        ...state,
        permissions: response.permissions,
        ratingsBreakdown: response.ratingsBreakdown,
        userReview: currentUserReview
          ? {
              type: 'CREATED' as const,
              review: reviewToReviewState(currentUserReview),
            }
          : state.userReview,
        reviewList: {
          chunk,
          prependCurrentUserReview: false,
          type: 'READY' as const,
          currentPage: 1,
        },
        reviewsById: currentUserReview ? omit(reviews, currentUserReview.id) : reviews,
        pendingRefetchId: undefined,
      };
    }
    case REFETCH_REVIEWS_FAILURE: {
      if (state.type !== 'READY') {
        return state;
      }
      return {
        ...state,
        effects: {
          ...state.effects,
          toast: { skin: 'error', message: action.payload.error, timestamp: Date.now() },
        },
        pendingRefetchId: undefined,
      };
    }
    default:
      throw unreachable(action);
  }
}
