import { AllActions } from '../store/store-types';
import { BiLogger, DefaultBiValues } from './bi-types';

type AllActionKeys = AllActions['type'];

type ReportProps = Parameters<BiLogger['report']>[0];

type OmitedEventValues = DefaultBiValues | 'post_stable_id';

export const createActionMatcher = (logger: BiLogger) => {
  const actionMap: {
    [key in AllActionKeys]?: (action: any) => ReportProps;
  } = {};
  const matcher = {
    match: <T extends AllActionKeys, U extends (args: any) => any>(
      actionType: T[],
      biEvent: U,
      getEventParams: (
        action: Extract<AllActions, { type: T }>,
      ) => Omit<Required<Parameters<U>[0]>, OmitedEventValues> | void,
    ) => {
      actionType.map((actionType) => {
        if (actionMap[actionType]) {
          throw new Error(`Action matcher for ${actionType} already exists`);
        } else {
          actionMap[actionType] = (action: Extract<AllActions, { type: T }>) =>
            biEvent(getEventParams(action));
        }
      });
      return matcher;
    },
    execute: (action: AllActions) => {
      const getEventParams = actionMap[action.type];
      const eventParams = getEventParams?.(action);
      if (eventParams) {
        logger.report(eventParams);
      }
    },
  };

  return matcher;
};
