import { StateWrapper } from '../../common/common-types';
import { ReduxStore } from '../create-redux-store';
import { createPatch } from './create-patch';
import { State } from '~reviews/common/store/store-types';

export function createStateOptimizer(
  store: ReduxStore,
  onChange: (update: StateWrapper[]) => void,
  initialState: State,
) {
  let isDisabled = false;
  let patchNumber = 0;
  let prevState = store.getState();
  let queue: StateWrapper[] = [
    {
      type: 'SET',
      state: initialState,
    },
  ];

  const updateState = (state: StateWrapper) => {
    queue.push(state);
    onChange(queue);
  };

  const listener = () => {
    const nextState = store.getState();
    if (nextState === prevState) {
      return;
    }

    if (isDisabled) {
      updateState({ type: 'SET', state: store.getState() });
      return;
    }
    patchNumber++;
    const patch = createPatch(prevState, nextState);
    updateState({ type: 'PATCH', patchNumber, patch });
    prevState = nextState;
  };

  return {
    disable: () => (isDisabled = true),
    subscribe: () => store.subscribe(listener),
    requestFullState: () => {
      patchNumber = 0;
      queue = [];
      updateState({ type: 'SET', state: store.getState() });
    },
    flush: () => {
      queue = [];
    },
    getState: () => queue,
  };
}
