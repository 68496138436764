import {
  Member,
  AttributeSummary,
  PagingMetadataV2,
  ReviewsSorting,
  Permission,
  Vote,
  Fieldset,
  Configuration,
  ListReviewsByEntityIdResponse,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { flow, merge } from 'lodash';
import { Review } from '~reviews/controller/lib/reviews-api-types';
import { FormSettings } from '~reviews/storybook-utils/reviews-story-wrappers';
import { toServerEnrichedReview } from '~reviews/controller/lib/converters/convert-to-server';

export const getRatingSummary = (reviews: Review[]): AttributeSummary => {
  const ratings = reviews.map((review) => review.content.rating);

  const breakdownMap = ratings.reduce<Record<number, number>>((acc, val) => {
    acc[val] = (acc[val] ?? 0) + 1;
    return acc;
  }, {});

  return {
    total: reviews.length,
    average: ratings.reduce((a, b) => a + b, 0) / ratings.length,
    valueBreakdown: Object.keys(breakdownMap).map((rating) => ({
      value: Number(rating),
      total: breakdownMap[Number(rating)],
    })),
  };
};

export const getMetadata = ({
  reviews,
  cursors,
  namespace,
  contextId,
}: {
  reviews: Review[];
  cursors: { prev?: string; next?: string };
  namespace: string;
  contextId: string;
}): PagingMetadataV2 => {
  return {
    count: reviews.filter((r) => r.namespace === namespace && r.resourceId === contextId).length,
    cursors,
  };
};

const getSortedList = (reviews: Review[], sort: ReviewsSorting) => {
  switch (sort) {
    case ReviewsSorting.NEWEST:
    case ReviewsSorting.MOST_RELEVANT:
      return reviews.sort((a, b) => (b.createdDate > a.createdDate ? 1 : -1));
    case ReviewsSorting.OLDEST:
      return reviews.sort((a, b) => (b.createdDate < a.createdDate ? 1 : -1));
    case ReviewsSorting.HELPFUL:
      return reviews.sort((a, b) => b.helpfulness - a.helpfulness);
    case ReviewsSorting.HIGHEST_RATED:
      return reviews.sort((a, b) => b.content.rating - a.content.rating);
    case ReviewsSorting.LOWEST_RATED:
      return reviews.sort((a, b) => a.content.rating - b.content.rating);
    default:
      return reviews;
  }
};

const getList = ({
  reviews: inputReviews,
  sort,
  ratingFilter,
  reviewIdsFilter,
  contextId,
  namespace,
}: {
  reviews: Review[];
  sort: ReviewsSorting;
  ratingFilter?: number;
  reviewIdsFilter?: string[];
  contextId: string;
  namespace: string;
}) => {
  return flow(
    (reviews: Review[]) => reviews.filter((review) => contextId === review.resourceId),
    (reviews) => reviews.filter((review) => namespace === review.namespace),
    (reviews) => getSortedList(reviews, sort),
    ratingFilter
      ? (reviews) => reviews.filter((review) => review.content.rating === ratingFilter)
      : (reviews) => reviews,
    reviewIdsFilter
      ? (reviews) => reviews.filter((review) => reviewIdsFilter.includes(review.id))
      : (reviews) => reviews,
  )(inputReviews);
};

export const listStub = (params: {
  reviews: Review[];
  limit: number;
  sorting: ReviewsSorting;
  cursor?: string;
  ratingFilter?: number;
  reviewIdsFilter?: string[];
  permissions: Permission[];
  votes?: Vote[];
  currentMember?: Member;
  fieldsets: Fieldset[];
  currentUserReview?: Review;
  configuration?: Configuration;
  contextId: string;
  namespace: string;
}): ListReviewsByEntityIdResponse => {
  const {
    reviews,
    cursor,
    reviewIdsFilter,
    permissions,
    votes,
    currentMember,
    fieldsets,
    currentUserReview,
    configuration,
    contextId,
    namespace,
  } = params;

  const { limit, offset, sort, ratingFilter } = cursor
    ? parseCursor(cursor)
    : { limit: params.limit, offset: 0, sort: params.sorting, ratingFilter: params.ratingFilter };

  const orderedReviews = getList({
    reviews,
    sort,
    ratingFilter,
    reviewIdsFilter,
    contextId,
    namespace,
  });

  const cursors = {
    prev:
      offset > 0 ? makeCursor({ limit, offset: offset - limit, sort, ratingFilter }) : undefined,
    next:
      offset + limit < orderedReviews.length
        ? makeCursor({ limit, offset: offset + limit, sort, ratingFilter })
        : undefined,
  };
  return {
    reviews: orderedReviews
      .slice(offset, offset + limit)
      .map((r) => ({
        ...r,
        currentUserVote: votes?.find((v) => v.reviewId === r.id) ?? (r.currentUserVote as any),
      }))
      .map(toServerEnrichedReview),
    metadata: getMetadata({ reviews: orderedReviews, cursors, contextId, namespace }),
    additionalData: {
      overallRatingSummary: fieldsets.includes(Fieldset.OVERALL_RATING_SUMMARY)
        ? getRatingSummary(orderedReviews)
        : undefined,
      permissions: fieldsets.includes(Fieldset.CURRENT_USER_PERMISSIONS) ? permissions : undefined,
      currentMember: fieldsets.includes(Fieldset.CURRENT_MEMBER) ? currentMember : undefined,
      currentUserReview:
        fieldsets.includes(Fieldset.CURRENT_USER_REVIEW) && currentUserReview
          ? toServerEnrichedReview(currentUserReview)
          : undefined,
      config: fieldsets.includes(Fieldset.CONFIGURATION) ? configuration : undefined,
    },
  };
};

const makeCursor = ({
  limit,
  offset,
  sort,
  ratingFilter,
}: {
  limit: number;
  offset: number;
  sort: ReviewsSorting;
  ratingFilter?: number;
}) => {
  return `${limit}-${offset}-${sort}-${ratingFilter ?? ''}`;
};
const parseCursor = (
  cursor: string,
): { limit: number; offset: number; sort: ReviewsSorting; ratingFilter?: number } => {
  const [limit, offset, sort, ratingFilter] = cursor.split('-');
  return {
    limit: Number(limit),
    offset: Number(offset),
    sort: sort as ReviewsSorting,
    ratingFilter: ratingFilter ? Number(ratingFilter) : undefined,
  };
};

export const makeDefaultServerConfiguration = (formConfig?: FormSettings): Configuration => {
  const DEFAULT_CONFIG = {
    title: { limit: 1000, enabled: true, required: true },
    body: { limit: 5000, enabled: true, required: true },
    media: { limit: 5, enabled: true, required: false },
    customFieldNames: {},
  };
  const config = merge({}, DEFAULT_CONFIG, formConfig);
  const { title, body, media, publishButtonText, ctaButtonText, ratingLabel } = config;
  const customFieldNames = {
    ...(publishButtonText ? { publish: publishButtonText } : {}),
    ...(ctaButtonText ? { addReview: ctaButtonText } : {}),
    ...(ratingLabel ? { rating: ratingLabel } : {}),
    ...(title?.enabled ? { title: title?.label } : {}),
    ...(body?.enabled ? { body: body?.label } : {}),
    ...(media?.enabled ? { media: media?.allowedMedia } : {}),
  };
  return {
    ...(title
      ? { title: { maxLength: title.enabled ? title.limit : 0, required: title.required } }
      : {}),
    ...(body
      ? { body: { maxLength: body.enabled ? body.limit : 0, required: body.required } }
      : {}),
    ...(media
      ? { media: { limit: media.enabled ? media.limit : 0, required: media.required } }
      : {}),
    customFieldNames,
    helpfulVotes: { active: true, helpfulVotesOnly: true },
  };
};
