import { Review } from '~reviews/controller/lib/reviews-api-types';

export const getTextCharCount = (content: string) => {
  return content.length;
};

export const getReviewImageCount = (review: Pick<Review, 'content'>) =>
  review.content.media.filter((m) => 'image' in m).length;

export const getReviewVideoCount = (review: Pick<Review, 'content'>) =>
  review.content.media.filter((m) => 'video' in m).length;
