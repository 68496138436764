import { unreachable } from '~/ts-utils';
import { updateCurrentUserReviewVote } from '../review-state-invariant-utils';
import { ReviewsStateReady } from '../reviews-types';
import { VoteActions, VOTE_FAILURE, VOTE_REQUEST, VOTE_SUCCESS } from './review-vote-actions';

export const reviewsVoteReducer = (
  state: ReviewsStateReady,
  action: VoteActions,
): ReviewsStateReady => {
  switch (action.type) {
    case VOTE_REQUEST: {
      const { reviewId, nextCurrentUserVoteState } = action.payload;
      return updateCurrentUserReviewVote({
        state,
        reviewId,
        updateCurrentUserVote: () => nextCurrentUserVoteState,
      });
    }
    case VOTE_FAILURE: {
      const { reviewId, error, nextCurrentUserVoteState } = action.payload;
      return updateCurrentUserReviewVote({
        state,
        reviewId,
        updateCurrentUserVote: () => nextCurrentUserVoteState,
        updateEffects: () => ({
          highlight: { type: 'CRUD_ERROR', id: reviewId, timestamp: Date.now() },
          toast:
            error.type === 'SILENT'
              ? state.effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    case VOTE_SUCCESS: {
      const { reviewId, nextCurrentUserVoteState } = action.payload;
      return updateCurrentUserReviewVote({
        state,
        reviewId,
        updateCurrentUserVote: () => nextCurrentUserVoteState,
      });
    }
    default:
      throw unreachable(action);
  }
};
