import { isPlainObject } from 'lodash';

export const DELETE = 0 as const;
export const SET = 1 as const;
export const UPDATE = 2 as const;

type Leaf = { t: typeof DELETE } | { t: typeof SET; v: any } | { t: typeof UPDATE; v: Patch };

type Patch = { [key: string]: Leaf };

export const createPatch = <T1 extends {}, T2 extends {}>(a: T1, b: T2): Patch => {
  const result: Patch = {};
  for (const key in a) {
    if (!(key in b)) {
      result[key] = { t: DELETE };
      // @ts-expect-error
    } else if (a[key] === b[key]) {
      continue;
      // @ts-expect-error
    } else if (isPlainObject(a[key]) && isPlainObject(b[key])) {
      // @ts-expect-error
      result[key] = { t: UPDATE, v: createPatch(a[key], b[key]) };
    } else {
      // @ts-expect-error
      result[key] = { t: SET, v: b[key] };
    }
  }

  for (const key in b) {
    if (!(key in a)) {
      result[key] = { t: SET, v: b[key] };
    }
  }
  return result;
};
