import { unreachable } from '~/ts-utils';
import {
  ReviewsPagingActions,
  FETCH_REVIEWS_PAGE_FAILURE,
  FETCH_REVIEWS_PAGE_REQUEST,
  FETCH_REVIEWS_PAGE_SUCCESS,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILURE,
} from './reviews-paging-actions';
import { ReviewsStateReady } from '../reviews-types';
import { ReviewChunk } from './reviews-paging-types';
import { FetchReviewsResponse, Review } from '../../../../controller/lib/reviews-api-types';
import { ReviewState } from '../reviews/review-state-types';
import { omit } from 'lodash';

export function reviewsPagingReducer(
  state: ReviewsStateReady,
  action: ReviewsPagingActions,
): ReviewsStateReady {
  switch (action.type) {
    case FETCH_REVIEWS_REQUEST: {
      return {
        ...state,
        config: {
          ...state.config,
          pagination: action.payload.pagination,
        },
        reviewList: {
          type: 'PENDING',
          pendingRequestId: action.payload.requestId,
          prevState:
            state.reviewList.type === 'PENDING' ? state.reviewList.prevState : state.reviewList,
        },
        effects: {
          ...state.effects,
          highlight: {
            type: 'SCROLL_TO_TOP',
            timestamp: Date.now(),
          },
        },
      };
    }
    case FETCH_REVIEWS_PAGE_REQUEST: {
      return {
        ...state,
        reviewList: {
          type: 'PENDING',
          pendingRequestId: action.payload.requestId,
          prevState:
            state.reviewList.type === 'PENDING' ? state.reviewList.prevState : state.reviewList,
        },
        effects: {
          ...state.effects,
          highlight: {
            type: 'SCROLL_TO_TOP',
            timestamp: Date.now(),
          },
        },
      };
    }
    case FETCH_REVIEWS_PAGE_SUCCESS:
    case FETCH_REVIEWS_SUCCESS: {
      const { requestId, response } = action.payload;
      if (state.reviewList.type !== 'PENDING' || state.reviewList.pendingRequestId !== requestId) {
        return state;
      }
      const [chunk, reviews] = reviewsFetchResponseToChunk(response);
      const currentPage = (() => {
        const { prevState } = state.reviewList;
        if (action.type === FETCH_REVIEWS_PAGE_SUCCESS && prevState.type === 'READY') {
          return action.payload.action === 'prev'
            ? prevState.currentPage - 1
            : prevState.currentPage + 1;
        } else {
          return 1;
        }
      })();
      return {
        ...state,
        reviewList: {
          type: 'READY',
          chunk,
          prependCurrentUserReview: false,
          ratingFilter: state.config.pagination.ratingFilter,
          currentPage,
        },
        userReview:
          state.userReview.type === 'CREATED' && reviews[state.userReview.review.reviewId]
            ? {
                type: 'CREATED',
                review: reviews[state.userReview.review.reviewId],
              }
            : state.userReview,
        reviewsById:
          state.userReview.type === 'CREATED'
            ? omit(reviews, state.userReview.review.reviewId)
            : reviews,
        effects: {
          ...state.effects,
          highlight: response.reviews[0]?.id
            ? {
                type: 'FOCUS_REVIEW',
                id: response.reviews[0].id,
                timestamp: Date.now(),
              }
            : state.effects.highlight,
        },
      };
    }
    case FETCH_REVIEWS_PAGE_FAILURE:
    case FETCH_REVIEWS_FAILURE: {
      const { requestId, error } = action.payload;
      if (state.reviewList.type !== 'PENDING' || state.reviewList.pendingRequestId !== requestId) {
        return state;
      }
      return {
        ...state,
        reviewList: state.reviewList.prevState,
        effects: {
          ...state.effects,
          toast: { skin: 'error', message: error, timestamp: Date.now() },
        },
      };
    }

    default:
      throw unreachable(action);
  }
}

export const reviewsFetchResponseToChunk = (
  response: FetchReviewsResponse,
): [ReviewChunk, Record<string, ReviewState>] => {
  return [
    {
      reviewIds: response.reviews.map((c) => c.id),
      nextCursor: response.metadata.cursors.next,
      previousCursor: response.metadata.cursors.prev,
    },
    response.reviews.reduce<Record<string, ReviewState>>((acc, review) => {
      acc[review.id] = reviewToReviewState(review);
      return acc;
    }, {}),
  ];
};

export const reviewToReviewState = (review: Review): ReviewState => {
  const { reply, ...reviewNoReply } = review;

  return {
    type: 'REVIEW_READY' as const,
    review: reviewNoReply,
    reviewId: review.id,
    replyState: reply
      ? {
          type: 'REPLY_READY' as const,
          parentId: review.id,
          reply,
        }
      : undefined,
    votes: {
      currentUserVote: review.currentUserVote,
      scoresWithoutUserVote: {
        upvotes: review.foundHelpful - (review.currentUserVote.type === 'UPVOTED' ? 1 : 0),
        downvotes: review.foundUnhelpful - (review.currentUserVote.type === 'DOWNVOTED' ? 1 : 0),
      },
    },
  };
};
