import { Message } from '../../common-types';

export const SHOW_TOAST = 'SHOW_TOAST' as const;

export const showToast = (payload: {
  message: Message;
  skin: 'success' | 'error' | 'status';
  isHidden?: boolean;
}) => ({
  type: SHOW_TOAST,
  payload,
});

export type ShowToast = ReturnType<typeof showToast>;
