import { AppThunk } from '../store-types';

export const USER_JOIN_COMMUNITY_SUCCESS = 'USER_JOIN_COMMUNITY_SUCCESS' as const;
export const userJoinCommunitySuccess = () => ({ type: USER_JOIN_COMMUNITY_SUCCESS });
export type JoinCommunityAction = ReturnType<typeof userJoinCommunitySuccess>;

const POPUP_URL = `https://apps.wix.com/_api/members-area/public/modal/join-community`;

export const showJoinCommunityPopup =
  (): AppThunk<Promise<boolean>> =>
  (dispatch, getState, { wixCodeApi, compId }) => {
    const onClose = ({ message }: any) => {
      if (message?.payload?.inCommunity) {
        dispatch(userJoinCommunitySuccess());
        return true;
      } else {
        return false;
      }
    };

    return wixCodeApi.window
      .openPopup(
        POPUP_URL,
        {
          theme: 'BARE',
          // @ts-expect-error TODO: why lib declared is a number?
          width: '100%',
          // @ts-expect-error
          height: '100%',
          position: {
            origin: 'FIXED',
            placement: 'CENTER',
            x: 0,
            y: 0,
          },
        },
        compId,
      )
      .then(onClose)
      .catch(onClose);
  };
