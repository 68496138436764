import {
  ReviewsPagingActions,
  FetchReviewsInitialActions,
  isReviewsFetchInitialAction,
  isReviewsPagingAction,
  UnmountResourceAction,
  UNMOUNT_RESOURCE,
  isReviewsRefetchAction,
  FetchDeeplinkActions,
  isFetchDeeplinkAction,
  FOCUS_ROOT,
  FocusRootAction,
} from './paging/reviews-paging-actions';
import { ReviewsCrudAction, isReviewsCrudAction } from './reviews/reviews-crud-actions';
import { AllActions } from '../store-types';
import { RepliesCrudAction, isRepliesCrudAction } from './replies/replies-crud-actions';
import { ShowToast, SHOW_TOAST } from './show-toast-action';
import { isVoteAction, VoteActions } from './votes/review-vote-actions';

// All actions included here must have resourceId parameter so it could be routed to
// specific resource state reducer
export type ReviewsActions =
  | FetchReviewsInitialActions
  | FetchDeeplinkActions
  | UnmountResourceAction
  | FocusRootAction
  | ReviewsPagingActions
  | ReviewsCrudAction
  | RepliesCrudAction
  | VoteActions
  | ShowToast;

export const isReviewsAction = (action: AllActions): action is ReviewsActions => {
  return (
    isReviewsFetchInitialAction(action) ||
    isFetchDeeplinkAction(action) ||
    isReviewsRefetchAction(action) ||
    isReviewsPagingAction(action) ||
    isRepliesCrudAction(action) ||
    isReviewsCrudAction(action) ||
    isVoteAction(action) ||
    action.type === UNMOUNT_RESOURCE ||
    action.type === FOCUS_ROOT ||
    action.type === SHOW_TOAST
  );
};
